<template>
	<h2 style="margin: 0 auto; text-align: center;margin-top: 4%;color:#409EFF ;" >储存单位在线生成</h2>
		<div style="margin-top: 4%;">
		<el-col :sm="13" style="margin: 0 auto;">
			<el-form :model="storageData"  label-width="120px">
				<el-row >
					<el-col :sm="23">
						<el-form-item label="容量大小">
							<el-input v-model.number="storageData.size" @input="generate()"></el-input>
						</el-form-item> 
					</el-col>
				</el-row>
				<el-row>
					<el-col>
						<el-form-item label="存储单位">
							<el-radio-group v-model="storageData.checkunit" size="small" @change="generate()">
							  <el-radio-button label="bit">比特(bit)</el-radio-button>
							  <el-radio-button label="byte">字节(byte)</el-radio-button>
							  <el-radio-button label="kiloByte">千字节(KB)</el-radio-button>
							  <el-radio-button label="megaByte">兆字节(MB)</el-radio-button>
							  <el-radio-button label="gigaByte">千兆字节(GB)</el-radio-button>
							  <el-radio-button label="teraByte">太字节(TB)</el-radio-button>
							  <el-radio-button label="petaByte">拍字节(PB)</el-radio-button>
							  <el-radio-button label="exaByte">艾字节(EB)</el-radio-button>
							  <el-radio-button label="zettaByte">泽字节(ZB)</el-radio-button>
							  <el-radio-button label="yottaByte">尧字节(YB)</el-radio-button>
							</el-radio-group>
						</el-form-item> 
					</el-col>
				</el-row>
				
				<el-row style="margin-top: 4%;">
					<el-row :gutter="20">
						<el-col :sm="20">
							<el-form-item label="比特(b)">
								<el-input readonly v-model="storageData.bit"></el-input>
							</el-form-item> 
						</el-col>
						<el-col :sm="4">
								<el-button  @click="copy(JSON.stringify(storageData.bit))" type="primary" plain>复制</el-button>
						</el-col>
					</el-row>
					<el-row :gutter="20">
						<el-col :sm="20">
							<el-form-item label="字节(B)">
								<el-input readonly v-model="storageData.byte"></el-input>
							</el-form-item>
						</el-col>
						<el-col :sm="4">
								<el-button  @click="copy(JSON.stringify(storageData.byte))" type="primary" plain>复制</el-button>
						</el-col>
					</el-row>
				</el-row>
				
				<el-row>
					<el-row :gutter="20">
						<el-col :sm="20">
							<el-form-item label="千字节(KB)">
								<el-input readonly v-model="storageData.kiloByte"></el-input>
							</el-form-item>
						</el-col>
						<el-col :sm="4">
								<el-button  @click="copy(JSON.stringify(storageData.kiloByte))" type="primary" plain>复制</el-button>
						</el-col>
					</el-row>
					<el-row :gutter="20">
						<el-col :sm="20">
							<el-form-item label="兆字节(MB)">
								<el-input readonly v-model="storageData.megaByte"></el-input>
							</el-form-item>
						</el-col>
						<el-col :sm="4">
								<el-button  @click="copy(JSON.stringify(storageData.megaByte))" type="primary" plain>复制</el-button>
						</el-col>
					</el-row>
				</el-row>
				
				<el-row>
					<el-row :gutter="20">
						<el-col :sm="20">
							<el-form-item label="千兆字节(GB)">
								<el-input readonly v-model="storageData.gigaByte"></el-input>
							</el-form-item>
						</el-col>
						<el-col :sm="4">
								<el-button  @click="copy(JSON.stringify(storageData.gigaByte))" type="primary" plain>复制</el-button>
						</el-col>
					</el-row>
					<el-row :gutter="20">
						<el-col :sm="20">
							<el-form-item label="太字节(TB)">
								<el-input readonly v-model="storageData.teraByte"></el-input>
							</el-form-item >
						</el-col>
						<el-col :sm="4">
								<el-button  @click="copy(JSON.stringify(storageData.teraByte))" type="primary" plain>复制</el-button>
						</el-col>
					</el-row>
				</el-row>
				
				<el-row>
					<el-row :gutter="20">
						<el-col :sm="20">
							<el-form-item label="拍字节(PB)">
								<el-input readonly v-model="storageData.petaByte"></el-input>
							</el-form-item >
						</el-col>
						<el-col :sm="4">
							<el-button  @click="copy(JSON.stringify(storageData.petaByte))" type="primary" plain>复制</el-button>
						</el-col>
					</el-row>
					<el-row :gutter="20">
						<el-col :sm="20">
						<el-form-item label="艾字节(EB)">
							<el-input readonly v-model="storageData.exaByte"></el-input>
						</el-form-item >
						</el-col>
						<el-col :sm="4">
								<el-button  @click="copy(JSON.stringify(storageData.exaByte))" type="primary" plain>复制</el-button>
						</el-col>
					</el-row>
				</el-row>
				
				<el-row>
					<el-row :gutter="20">
						<el-col :sm="20">
							<el-form-item label="泽字节(ZB)">
								<el-input readonly v-model="storageData.zettaByte"></el-input>
							</el-form-item >
						</el-col>
						<el-col :sm="4">
								<el-button  @click="copy(JSON.stringify(storageData.zettaByte))" type="primary" plain>复制</el-button>
						</el-col>
					</el-row>
					<el-row :gutter="20">
						<el-col :sm="20">
							<el-form-item label="尧字节(YB)">
								<el-input readonly v-model="storageData.yottaByte"></el-input>
							</el-form-item >
						</el-col>
						<el-col :sm="4">
								<el-button  @click="copy(JSON.stringify(storageData.yottaByte))" type="primary" plain>复制</el-button>
						</el-col>
					</el-row>
				</el-row>
				<el-row>
				  <section class="content element-doc content">
					  <h3 id="ji-ben-yong-fa">存储单位</h3>
					  <p>存储单位：存储在计算机硬盘或内存中的信息容量标准，最小计量单位是“位”（bit，比特），一个比特位表示一个二进制的0或1在计算机中所占用的存储空间</p>
				  </section>
				</el-row>
				<el-row>
				  <section class="content element-doc content">
					  <h3 id="ji-ben-yong-fa">传输单位</h3>
					  <p>传输单位：在计算机网络中称为带宽，宽带传输速率的单位为bps，bps是bit per second的缩写，表示每秒钟传输多少比特位信息（很多人都会把这里的bit误以为是Byte，也就是错把位当成字节），例如：带宽的单位是10Mb/s，这里其实指的是每秒传输10兆位，而不是10兆字节数据，因此将位数需要除以8换算成字节数，也就是每秒传输1.25兆字节，即10Mbit/s = 1.25MByte/s</p>
				  </section>
				</el-row>
				<el-row>
				  <section class="content element-doc content">
					  <h3 id="ji-ben-yong-fa">换算公式</h3>
					  <ul>
						  <li><p>1KB（Kilo Byte，千字节）= 1024B（Byte）</p></li>
						  <li><p>1MB（Mega Byte，兆字节）= 1024KB</p></li>
						  <li><p>1GB（Giga Byte，吉字节）= 1024MB</p></li>
						  <li><p>1TB（Tera Byte，太字节）= 1024GB</p></li>
						  <li><p>1PB（Peta Byte，拍字节）= 1024TB</p></li>
						  <li><p>1EB（Exa Byte，艾字节）= 1024PB</p></li>
						  <li><p>1ZB（Zeta Byte，泽字节）= 1024EB</p></li>
						  <li><p>1YB（Yotta Byte，尧字节）= 1024ZB</p></li>
						  <li><p>1BB（Bronto Byte，珀字节）= 1024YB</p></li>
						  <li><p>1NB（Nona Byte，诺字节）= 1024BB</p></li>
						  <li><p>1DB（Dogga Byte，刀字节）= 1024NB</p></li>
					  </ul>
				  </section>
				</el-row>
			</el-form>
		</el-col>
		</div>
</template>

<script setup>
import {ref} from 'vue'
import {moduleEvent as moduleaction} from '../../common/actionrecord'
import {clipboard as copy} from '../../common/actionrecord'	
	let storageData = ref({
		moduleid: 2006,
		size:"",
		checkunit:"megaByte",
		bit: "",
		byte: "",
		kiloByte: "",
		megaByte: "",
		gigaByte: "",
		teraByte:"",
		petaByte:"",
		exaByte:"",
		zettaByte:"",
		yottaByte:"",
	})
	
//页面预览事件	
moduleaction(storageData.value.moduleid,1);	

	//数据生成逻辑
	let generate = () => {
		var sizeval=storageData.value.size
		if(sizeval=="" || typeof sizeval !="number"){
			console.log("不符合要求")
			return;
		}
		if(storageData.value.checkunit=="bit"){
			storageData.value.bit=sizeval
			storageData.value.byte=sizeval/8
		}else if(storageData.value.checkunit=="byte"){
			storageData.value.byte=sizeval;
		}else{
			storageData.value.byte=sizeval*Math.pow(1024,getPower(storageData.value.checkunit));
		}
		storageData.value.bit=storageData.value.byte*8
		storageData.value.kiloByte=storageData.value.byte/Math.pow(1024,1);
		storageData.value.megaByte=storageData.value.byte/Math.pow(1024,2);
		storageData.value.gigaByte=storageData.value.byte/Math.pow(1024,3);
		storageData.value.teraByte=storageData.value.byte/Math.pow(1024,4);
		storageData.value.petaByte=storageData.value.byte/Math.pow(1024,5);
		storageData.value.exaByte=storageData.value.byte/Math.pow(1024,6);
		storageData.value.zettaByte=storageData.value.byte/Math.pow(1024,7);
		storageData.value.yottaByte=storageData.value.byte/Math.pow(1024,8);
		moduleaction(storageData.value.moduleid,2);
		}
		
		let getPower = (unit) => {
			var power=new Map();
			power.set("kiloByte",1)
			power.set("megaByte",2)
			power.set("gigaByte",3)
			power.set("teraByte",4)
			power.set("petaByte",5)
			power.set("exaByte",6)
			power.set("zettaByte",7)
			power.set("yottaByte",8)
			return power.get(unit);
		}
</script>
<style>

</style>